/*!

=========================================================
* Argon Dashboard PRO React Nodejs - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react-nodejs
* Copyright 2021 Creative Tim (http://www.creative-tim.com)

* Coded by Creative Tim
* Coded by ProjectData

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect } from "react";
// node.js library that concatenates classes (strings)
// javascipt plugin for creating charts
import { Chart } from "chart.js";
import { Blocks } from 'react-loader-spinner'
// react plugin used to create charts
// reactstrap components
import {
  Badge, Button,
  Card, CardFooter,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media, Pagination, PaginationItem, PaginationLink,
  Progress,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap";

// core components

import { chartOptions, parseOptions, } from "variables/charts.js";
import axios from "axios";
import { getBets, getGames } from "../../../network/ApiAxios";

function Bets(props) {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({ })
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [games, setGames] = useState({})
  
  useEffect(() => {
    console.log('props', props.match.params.id)
    if(props.match.params.id)
      fetchGames(props.match.params.id)
  }, [])
  const fetchGames = async (id) => {
    setLoading(true)
    const response = await getBets(id)
    if (response.status === 200) {
      const gameData = response.data;
      setData(gameData)
      setGames(gameData.playerOdds)
      setCurrentPage(1);
      setTotalPages(1)
      setLoading(false)
      //setGames(response.data.data)
    }
  }
  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }
  const onChangePage = (page)=>{
    console.log('page', page*10)
    setCurrentPage(page+1)
    setGames(data.slice(page*10, (page*10)+10))
  }
  return (
    <>
      <Container className="mt--6" fluid>
        <Row>
          <Col xl="12">
            <Row>
              <div className="col">
                <Card>
                  <CardHeader className="border-0">
                    <h3 className="mb-0">Bets</h3>
                  </CardHeader>
                  {!loading && <Table className="align-items-center table-flush">
                    <thead className="thead-light">
                      <tr>
                      <th className="sort" data-sort="name" scope="col">
                        Team Name
                      </th>
                      {
                        data.odds && data.odds.map((odd)=>
                          <th className="sort" data-sort="budget" scope="col">
                            {odd.market}
                        </th>
                      )}
                    </tr>
                    </thead>
                    <tbody className="list">
                      <tr>
                        <th scope="row">
                          <Media className="align-items-center">
                            <Media>
                                  <span className="name mb-0 text-sm">
                                    {data.away_team}
                                  </span>
                            </Media>
                          </Media>
                        </th>
                        {data.odds && data.odds.map((odd) =>
                          <td>
                            {`${odd.odds[0].price}`}
                          </td>
                        )}
                      </tr>
                      <tr>
                        <th scope="row">
                          <Media className="align-items-center">
                            <Media>
                                  <span className="name mb-0 text-sm">
                                    {data.home_team}
                                  </span>
                            </Media>
                          </Media>
                        </th>
                        {data.odds && data.odds.map((odd) =>
                          <td>
                            {`${odd.odds[1].price}`}
                          </td>
                        )}
                      </tr>
                    </tbody>
                  </Table>}
                  <CardFooter className="py-4">
                  </CardFooter>
                </Card>
              </div>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xl="12">
            <Row>
              <div className="col">
                <Card>
                  <CardHeader className="border-0">
                    <h3 className="mb-0">Player Prop</h3>
                  </CardHeader>
                  {!loading && <Table className="align-items-center table-flush">
                    <thead className="thead-light">
                    <tr>
                      <th className="sort" data-sort="name" scope="col">
                        Market Name
                      </th>
                      <th className="sort" data-sort="budget" scope="col">
                        Name
                      </th>
                      <th className="sort" data-sort="status" scope="col">
                        Bet Points
                      </th>
                      <th className="sort" data-sort="status" scope="col">
                        Probability
                      </th>
                      <th scope="col">Price</th>
                      <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody className="list">
                    {games.map((game) => (
                      <tr style={{'cursor':'pointer'}}
                          onClick={(e)=>{props.history.push(`/admin/bets/${game.id}`)}
                          }>
                        <th scope="row">
                          <Media className="align-items-center">
                            <Media>
                                <span className="name mb-0 text-sm">
                                  {game.market_name}
                                </span>
                            </Media>
                          </Media>
                        </th>
                        <td>
                          {game.name}
                        </td>
                        <td>
                          {game.bet_points}
                        </td>
                        <td>
                          {game.probability}
                        </td>
                        <td>
                          {game.price}
                        </td>
                        <td>
                          <Button
                            onClick={(e)=>{
                              e.stopPropagation();
                              e.preventDefault();
                            }}
                            color="primary" type="button" size="sm">
                            Publish
                          </Button>
                        </td>
                      </tr>
                    ))}
                    </tbody>
                  </Table>}
                  <CardFooter className="py-4">
                    <nav aria-label="...">
                      <Pagination
                        className="pagination justify-content-end mb-0"
                        listClassName="justify-content-end mb-0"
                      >
                        <PaginationItem className="disabled">
                          <PaginationLink
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            tabIndex="-1"
                          >
                            <i className="fas fa-angle-left"/>
                            <span className="sr-only">Previous</span>
                          </PaginationLink>
                        </PaginationItem>
                        {
                          Array.from(Array(totalPages)).map((item, index) =>
                            <PaginationItem className={index + 1 === currentPage ? "active" : ""}>
                              <PaginationLink
                                href="#pablo"
                                onClick={(e) => {
                                  e.preventDefault()
                                  onChangePage(index)
                                }}
                              >
                                {index + 1}
                              </PaginationLink>
                            </PaginationItem>
                          )
                        }
                      </Pagination>
                    </nav>
                  </CardFooter>
                </Card>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
      <Blocks
        visible={loading}
        height="80"
        width="80"
        ariaLabel="blocks-loading"
        wrapperStyle={{ display: 'block', margin: 'auto' }}
        wrapperClass="blocks-wrapper"
      />
    </>
  );
}

export default Bets;
